import { mapActions, mapGetters } from "vuex";
import salesInvoice from "@/modules/profile/components/sales-Invoice/index.vue";
import expandedInvoice from "@/modules/profile/components/expanded-invoice/index.vue";

export default {
    name: "distributor-storage-sales",
    props: {
        type: String,
    },
    data() {
        return {
            showMovingItems: true,
            openDocument: null,
        };
    },
    components: { expandedInvoice, salesInvoice },
    async created() {
        await this.fetchDocuments({ type: "clientsale" });
    },
    watch: {
        "$route.query.open": function(newVal) {
            if (newVal) {
                this.showMovingItems = false;
                const correspondingDocument = this.documents.find(doc => doc.id === +newVal);
                if (correspondingDocument) {
                    this.openDocument = correspondingDocument;
                }
            } else {
                this.showMovingItems = true;
                this.openDocument = null;
            }
        },
    },
    methods: {
        ...mapActions({
            fetchDocuments: "documents/GET_DOCUMENTS",
            fetchDocumentsNextPage: "documents/GET_DOCUMENTS_NEXT_PAGE",
        }),
        hideMovingItems(document) {
            this.showMovingItems = false;
            this.openDocument = document;
            this.$router.push({ query: { open: document.id } });
        },
        async loadMoreDocuments() {
            await this.fetchDocumentsNextPage({ type: "clientsale" });
        },
    },
    computed: {
        ...mapGetters({
            documents: "documents/documents",
            haveNextPage: "documents/haveNextPage",
            loading: "documents/loading",
            documentInfo: "documents/documentInfo",
        }),
    },
};
